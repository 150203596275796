import React from 'react'
import tw from 'twin.macro'
import huettnBg from '@images/events/huettn-header.png'
import flower_01 from '@images/flower_1.png'
import flower_02 from '@images/flower_2.png'

const Section = tw.section`w-full bg-radler pt-20 lg:px-2.5 lg:pt-[200px] overflow-hidden flex items-end justify-center relative`

const huettnHeader = () => {
  return (
    <Section>
      <img tw="z-20" src={huettnBg} alt="ERDINGER Hütt'n" />
      <img
        tw="object-cover absolute bottom-0 lg:bottom-7 left-0 lg:left-7 scale-40 origin-bottom-left lg:scale-75 3xl:scale-100"
        src={flower_01}
        alt="Hopfen"
      />
      <img
        tw="object-cover absolute top-20 lg:top-[135px] right-0 lg:right-7 scale-40 origin-top-right lg:scale-75 3xl:scale-100"
        src={flower_02}
        alt="Hopfen"
      />
    </Section>
  )
}

export default huettnHeader
