import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import Navbar from '@components/elements/Navbar'
import Footer from '@components/elements/Footer'
import Modal from '@components/elements/Modal/Modal'
import components from '../content/common'
import { AnimatePresence } from 'framer-motion'
import HuettnHeader from '@components/modules/HuettnHeader'
import huettnPosterPreview from '@images/events/Erdinger-Huettn-Illustration.jpg'

const Container = styled.div`
  filter: ${(props) => (props.modal ? 'blur(20px)' : 'none')};
  image-rendering: ${(props) =>
    props.isChrome ? '-webkit-optimize-contrast !important;' : 'undefined'};
`

const ContentWrap = tw.div`max-w-master mx-auto pb-10 pt-5 sm:py-10 md:py-24 px-5`
const Heading = tw.h1`font-lobster text-green text-[22px] md:text-4xl tracking-[0.02em] md:leading-[50px] pb-5 md:pb-8`
const Text = tw.p`text-15px leading-[18px] text-gray font-roboto sm:text-18px lg:tracking-wide`
const SubHeading = tw.h2`text-15px leading-[20px] text-gray font-roboto md:text-[24px] md:leading-[29px] lg:tracking-[0.02em] pb-5 md:pb-8 font-bold pr-4 2xl:mr-40 md:mr-32 sm:mr-24 mr-16`

const ErdingerHuettnPage = () => {
  const { modal, modalHandle, sticky } = useStateContext()

  useEffect(() => {
    if (modal) {
      document.documentElement.style.overflowY = 'hidden'
    }
    return () => {
      document.documentElement.style.overflowY = 'auto'
    }
  }, [modal])
  const isChrome = typeof window !== 'undefined' && !!window.chrome
  return (
    <>
      <Container modal={modal} isChrome={isChrome}>
        <Navbar content={components.navbar} />
        <HuettnHeader />
        <img 
          tw="w-full object-contain inline-block sm:h-auto"
          className=""
          src={huettnPosterPreview}
          alt="Herunterladen"
        />
        <ContentWrap>
          <Heading>
            Doppel-Premiere auf dem Herbstfest in Erding
          </Heading>

          <SubHeading tw="max-w-full">
            Erste ERDINGER Doppelstock-Hütt&rsquo;n mit den neuen Festwirten David Ritter und Manfred Kolbeck
          </SubHeading>
          <Text>
            Zünftig gefeiert wird auf dem Herbstfest in Erding schon immer – in diesem Jahr setzt der 
            ERDINGER Weißbräu sogar noch einen drauf und errichtet eine Doppelstock-Hütt&rsquo;n. Die legendäre 
            Party-Stimmung aus der ehemaligen Urweisse-Hütt&rsquo;n gibt&rsquo;s dieses Jahr hoch zwei! 
            Biergarten, zwei Außenbalkone und eine zweite Etage – das erwartet die Gäste der neuen 
            ERDINGER Hütt&rsquo;n, die am Eingang zum Festplatz an der Schützenstraße stehen wird. Mit 
            60 Sitzplätzen im Biergarten und 250 Sitzplätzen sowie 230 Stehplätzen innen bietet sie Platz für 
            bis zu 540 Personen.
          </Text>

          <SubHeading tw="max-w-full mt-10">
            Neue Hütt&rsquo;n gleich neue Festwirte
          </SubHeading>
          <Text>
            Für den Betrieb der Hütt&rsquo;n hat sich die Privatbrauerei ERDINGER Weißbräu für eine 
            Zusammenarbeit mit David Ritter und Manfred Kolbeck entschieden. Bereits im letzten Jahr haben sie 
            die Urweisse-Hütt&rsquo;n bei den Schwedenspielen in Erding betrieben und gezeigt, dass sie die 
            perfekten Partner für Veranstaltungen sind.
          </Text>

          <SubHeading tw="max-w-full mt-10">
            Mitreißende Live-Musik und fetzige Hütt&rsquo;n Gaudi
          </SubHeading>
          <Text>
            Beste Stimmung garantieren Gerry & Gary, Guten A-Band, da Rocka & da Waitla und zahlreiche weitere 
            Acts, die jeweils ab 17 Uhr aufspielen. Von 22 Uhr bis 23:30 Uhr heizt dann ein DJ ordentlich ein.
          </Text>

          <SubHeading tw="max-w-full mt-10">
            Speis und Trank
          </SubHeading>
          <Text>
            Und auch für das leibliche Wohl ist gesorgt: Es gibt köstliche Brotzeitbrettl und bayerische 
            Schmankerl im Reindl. ERDINGER Bierspezialitäten und eine breite Auswahl an weiteren Getränken 
            erfrischen durstige Kehlen.
          </Text>

          <SubHeading tw="max-w-full mt-10">
            Weitere Infos und Tischreservierungen
          </SubHeading>
          <Text>
            Tische können unter <a tw="font-bold underline"
              href="https://www.erdinger-huettn.de/"
              className="text-green"
              target="_blank"
            >
              erdinger-hüttn.de
            </a> während des Herbstfestes täglich ab 19 Uhr reserviert werden. Die Reservierungen erfolgen immer 
            tischweise für je 7 Personen. Geöffnet ist die ERDINGER Hütt&rsquo;n Montag bis Samstag ab dem 
            frühen Nachmittag und sonntags zum Weißwurstfrühstück zusätzlich von 11 bis 14 Uhr. 
            Jeder ist herzlich zum Mitfeiern eingeladen.
          </Text>

          {/*
          <br />
          <a
            href={huettnPosterDownload}
            download
            className="inline-block"
          >
            <img
              className="inline-block object-contain sm:h-auto"
              src={huettnPosterPreview}
              alt="Herunterladen"
            />
          </a>
          */}
        </ContentWrap>
      </Container>
      <AnimatePresence exitBeforeEnter>
        {modal && <Modal key="modal" handleClose={() => modalHandle(false)} />}
      </AnimatePresence>
      <Footer stickyVisible={sticky} />
    </>
  )
}

export default ErdingerHuettnPage
